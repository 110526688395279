module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dan Lubbers Photographs","short_name":"Dan Lubbers","icon":"src/assets/images/manifest/maskable_icon.png","icons":[{"src":"src/assets/images/manifest/src/assets/images/manifest/maskable_icon_x48.png","type":"image/png","sizes":"48x48","purpose":"maskable"},{"src":"src/assets/images/manifest/src/assets/images/manifest/maskable_icon_x72.png","type":"image/png","sizes":"72x72","purpose":"maskable"},{"src":"src/assets/images/manifest/src/assets/images/manifest/maskable_icon_x96.png","type":"image/png","sizes":"96x96","purpose":"maskable"},{"src":"src/assets/images/manifest/src/assets/images/manifest/maskable_icon_x128.png","type":"image/png","sizes":"128x128","purpose":"maskable"},{"src":"src/assets/images/manifest/src/assets/images/manifest/maskable_icon_x192.png","type":"image/png","sizes":"192x192","purpose":"any"},{"src":"src/assets/images/manifest/src/assets/images/manifest/maskable_icon_x384.png","type":"image/png","sizes":"384x384","purpose":"maskable"},{"src":"src/assets/images/manifest/src/assets/images/manifest/maskable_icon_x512.png","type":"image/png","sizes":"512x512","purpose":"maskable"}],"start_url":"/","background_color":"#0e0e0e","theme_color":"#0e0e0e","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b8a8d3c9ac90a172fde7fb40c792db4f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"importWorkboxFrom":"cdn"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
